import PatienetCard from './PatienetCard';
import { connect } from 'react-redux';

import './PatienetsListStyle.scss';

const PatienetsList = ({ showPts, changeState }) => {
    // console.log('docs --',showPts);
    
    return (
        <div className='ptnlist'>
            <div className='listHead'>
                <div className='title'>
                    Patients Lists
                </div>
            </div>
            <div className='allLists'>
                {showPts.map((pt, indx) => 
                    <PatienetCard key={indx} pt={pt} /> )
                }
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    showPts: state.showDatas.showPts,
})

const mapDispatchToProps = dispatch =>({
    
})


export default connect(mapStateToProps, mapDispatchToProps)(PatienetsList);
