import { drawerActionType } from "./DrawerActionType";

export const setDrawerToogle = isDrawerOpen =>({
    type: drawerActionType.SET_DRAWER_TOOGLE,
    payload: isDrawerOpen,
})

export const setSelectedDrawerMenu = selectedMenu =>({
    type: drawerActionType.SET_SELECTED_DRAWER_MENU,
    payload: selectedMenu,
})
