import React, { useState } from 'react';
import { FaTimes } from "react-icons/fa";
// import { FaUserMd, FaUserInjured } from "react-icons/fa";
import { connect } from 'react-redux';


// import DoctorProfile from '../doctorProfile/DoctorProfile';

import PatienetCard from '../Patienets/PatienetCard';
import DoctorCard from '../Doctors/DoctorCard';
import PrescriptionDetails from './PrescriptionDetails';

import rx_dummy from '../../assets/images/rx_dummy.jpeg'

import { eclipseStr } from '../../assets/utils/discreteFunc'
import './PrescriptionCardStyle.scss';
import moment from 'moment';

const PrescriptionCard = ({ patientsRxCount, doctorsRxCount, rx }) => {

    const [modalVisiblity, setModalVisiblity] = useState(false);
    const [whichModal, setWhichModal] = useState('');

    const toggleModal = () => {
        setModalVisiblity(!modalVisiblity)
    }

    const modalValHandle = (val) => {
        setWhichModal(val);
        toggleModal();
    }

    const openInNewTab = (id) => {
        window.open(process.env.REACT_APP_DESK_URL + 'prescription/' + id, '_blank').focus();
    }



    return (
        <div className='rxCard'>
            <div className='rxfrstSec'>
                <div className="imgWrapper" onClick={() => openInNewTab(rx.id)}>
                    <img src={rx_dummy} alt="" />
                </div>
                <div className='details'>
                    <div style={{ cursor: 'pointer', color: '#0a609e' }} onClick={() => { modalValHandle('pt') }}>
                        Patient: <span>{rx.patient ? rx.patient.name : null}</span>
                    </div>
                    <div style={{ cursor: 'pointer', color: '#0a609e' }} onClick={() => { modalValHandle('doc') }}>
                        Doctor: <span>{rx.doctor.title} {rx.doctor.full_name}</span>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline'}}>
                        S/S: <div style={{ maxHeight: '50px', overflow: 'auto', margin: '2px 0 2px 2px' }}>{rx.symptoms + ', ' + rx.signs}</div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline' }} >
                        Dx: <div style={{ maxHeight: '50px', overflow: 'auto', margin: '2px 0 2px 2px' }}>{rx.dx}</div>
                    </div>
                    <div>
                        FollowUp: <span>{rx.followup_date ? rx.followup_date : 'Not Required'}</span>
                    </div>
                </div>
            </div>
            <div className='scndSec'>
                <div className="advice">
                    Advice: <span>{rx.advice ? eclipseStr(rx.advice,50) : 'No Advice Available'}</span>
                </div>
                <div className="footer" >
                    <div className='token-id' onClick={() => { modalValHandle('rx') }}>#{rx.token}</div>
                    <div> Created at: {moment(rx.created_at).format("DD MMM YYYY")}</div>
                </div>
            </div>

            <div className="modal" style={{ visibility: modalVisiblity ? 'visible' : 'hidden' }}>
                {whichModal === 'doc' ?
                    <div className='modalWrapper'>
                        <DoctorCard doc={rx.doctor} />
                        <div className="closeBtn" onClick={() => toggleModal()}>
                            <FaTimes />
                        </div>
                    </div>

                    :
                    whichModal === 'pt' ?
                        <div className='modalWrapper'>
                            <PatienetCard pt={rx.patient} />
                            <div className="closeBtn" onClick={() => toggleModal()}>
                                <FaTimes />
                            </div>
                        </div>
                        :
                        <div className='modalWrapper'>
                            <PrescriptionDetails rx={rx} />
                            <div className="closeBtn" onClick={() => toggleModal()}>
                                <FaTimes size={20} />
                            </div>
                        </div>
                }
                {/* <div className="closeBtn" onClick={() => toggleModal()}>
                    <FaTimes />
                </div> */}
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    patientsRxCount: state.rxs.patientsRxCount,
    doctorsRxCount: state.rxs.doctorsRxCount,

})

const mapDispatchToProps = dispatch => ({

})


export default connect(mapStateToProps, mapDispatchToProps)(PrescriptionCard);
