import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router';

import { BsList, BsArrowLeft } from "react-icons/bs";
import { FaStethoscope, FaPrescription, FaUserMd, FaUserInjured, FaChartLine } from "react-icons/fa";

import { setShowDocs, setShowRxs, setShowPts } from '../../redux/showData/showDataAction';
import { setDrawerToogle, setSelectedDrawerMenu } from '../../redux/drawer/DrawerAction'

import './DrawerComponentStyle.scss';



export class DrawerComponent extends Component {
    
    menuAndShowRxHandle = () => {
        this.props.setSelectedDrawerMenu('rx');
        this.props.setShowRxs(this.props.totalRxs);
        this.props.history.push('/prescriptions')
    }
    menuAndShowDocHandle = () => {
        this.props.setSelectedDrawerMenu('doc');
        this.props.setShowDocs(this.props.totalDocs);
        this.props.history.push('/doctors')
    }
    menuAndShowPtHandle = () => {
        this.props.setSelectedDrawerMenu('pt');
        this.props.setShowPts(this.props.totalPts);
        this.props.history.push('/patients')
    }
    menuAndShowOvHandle = () => {
        this.props.setSelectedDrawerMenu('ov');
        this.props.history.push('/overview')
    }
    
    menuAndShowStHandle = () => {
        this.props.setSelectedDrawerMenu('st');
        this.props.history.push('/overview')
    }
    
    
    render() {
        
        const { isDrawerOpen, setDrawerToogle, history, selectedMenu } = this.props;
        
        return (
            <div style={isDrawerOpen? {minWidth: '200px'} : {maxWidth: '70px'}} className='drawerContainer'>
            {isDrawerOpen ?
                <div className='drawerHeader'> 
                    Dashboard 
                    <div className='drawericon' onClick={()=> setDrawerToogle(false)}> <BsArrowLeft size='25px'/> </div> 
                </div>
                :
                <div className='drawerHeader'> 
                    <div className='drawericon' onClick={()=> setDrawerToogle(true)}> <BsList size='25px'/> </div>
                </div>
            }
            {isDrawerOpen ?
                <div className="menus">
                    <div className="menuItem" style={{color: selectedMenu==='ov' ? 'mediumvioletred': null}} onClick={()=> this.menuAndShowOvHandle()}  >
                        <span style={{marginRight: '10px'}}>Overview</span> 
                        <FaStethoscope size={17}/> 
                    </div>
                    <div className="menuItem" style={{color: selectedMenu==='rx' ? 'mediumvioletred': null}} onClick={()=> this.menuAndShowRxHandle()} >
                        <span style={{marginRight: '10px'}}>Prescriptions</span> 
                        <FaPrescription size={17}/> 
                    </div>
                    <div className="menuItem" style={{color: selectedMenu==='doc' ? 'mediumvioletred': null}} onClick={()=> this.menuAndShowDocHandle()} >
                        <span style={{marginRight: '10px'}}>Doctors</span> 
                        <FaUserMd size={17}/> 
                    </div>
                    <div className="menuItem" style={{color: selectedMenu==='pt' ? 'mediumvioletred': null}} onClick={()=> this.menuAndShowPtHandle()} >
                        <span style={{marginRight: '10px'}}>Patients</span> 
                        <FaUserInjured size={17}/> 
                    </div>
                    <div className="menuItem" style={{color: selectedMenu==='st' ? 'mediumvioletred': null}} onClick={()=> this.menuAndShowStHandle()} >
                        <span style={{marginRight: '10px'}}>Statistics</span> 
                        <FaChartLine size={17}/> 
                    </div> 
                </div>
                :
                <div className="menus">
                    <div className="menuItem" style={{color: selectedMenu==='ov' ? 'mediumvioletred': null}} onClick={()=> this.menuAndShowOvHandle()}>
                        <FaStethoscope size={20}/> 
                    </div>
                    <div className="menuItem" style={{color: selectedMenu==='rx' ? 'mediumvioletred': null}} onClick={()=> this.menuAndShowRxHandle()} >
                        <FaPrescription size={20}/> 
                    </div>
                    <div className="menuItem" style={{color: selectedMenu==='doc' ? 'mediumvioletred': null}} onClick={()=> this.menuAndShowDocHandle()} >
                        <FaUserMd size={20}/> 
                    </div>
                    <div className="menuItem" style={{color: selectedMenu==='pt' ? 'mediumvioletred': null}} onClick={()=> this.menuAndShowPtHandle()} >
                        <FaUserInjured size={20}/> 
                    </div>
                    <div className="menuItem" style={{color: selectedMenu==='st' ? 'mediumvioletred': null}} onClick={()=> this.menuAndShowStHandle()} >
                        <FaChartLine size={20}/> 
                    </div> 
                </div>
            }
            
            
        </div>
        )
    }
}

const mapStateToProps = (state) => ({
    totalRxs: state.rxs.totalRxs,
    todayRxs: state.rxs.todayRxs,
    thisWeekRxs: state.rxs.thisWeekRxs,
    totalDocs: state.docs.totalDocs,
    todayDocs: state.docs.todayDocs,
    thisWeekDocs: state.docs.thisWeekDocs,
    totalPts: state.pts.totalPts,
    todayPts: state.pts.todayPts,
    thisWeekPts: state.pts.thisWeekPts,
    isDrawerOpen: state.drawer.isDrawerOpen,
    selectedMenu: state.drawer.selectedMenu
})

const mapDispatchToProps = dispatch =>({
    setShowDocs: showDocs => dispatch(setShowDocs(showDocs)),
    setShowPts: showPts => dispatch(setShowPts(showPts)),
    setShowRxs: showRxs => dispatch(setShowRxs(showRxs)),
    setDrawerToogle: isDrawerOpen => dispatch(setDrawerToogle(isDrawerOpen)),
    setSelectedDrawerMenu: selectedMenu => dispatch(setSelectedDrawerMenu(selectedMenu)),
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DrawerComponent))
