import { showDataActionType } from "./showDataActionType";

export const setShowRxs = showRxs =>({
    type: showDataActionType.SET_SHOW_RXS,
    payload: showRxs,
})

export const setShowDocs = showDocs =>({
    type: showDataActionType.SET_SHOW_DOCS,
    payload: showDocs,
})

export const setShowPts = showPts =>({
    type: showDataActionType.SET_SHOW_PTS,
    payload: showPts,
})

export const setShowRxsSubtitle = showRxsSubtitle =>({
    type: showDataActionType.SET_SHOW_RX_SUBTITLE,
    payload: showRxsSubtitle,
})
