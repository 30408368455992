import { ptActionType } from './PtActionType';

const INITIAL_STATE = {
    totalPts: [],
    todayPts: [],
    thisWeekPts: [],
}

const ptReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case (ptActionType.SET_TOTAL_PTS):
            return {
                ...state,
                totalPts: action.payload
            }
        case (ptActionType.SET_TODAY_PTS):
            return {
                ...state,
                todayPts: action.payload
            }
        case (ptActionType.SET_THISWEEK_PTS):
            return {
                ...state,
                thisWeekPts: action.payload
            }
        default: return state;
    }
}

export default ptReducer;