import React, { Component } from 'react';

import FormInput from '../form-input/FormInput.component';
import CustomButton from '../custom-button/CustomButton.component';
// import { auth, createUserProfileDocument } from '../../firebase/firebase.utils';

import './SignUp.style.scss';

export class SignUp extends Component {
  constructor() {
    super();
    this.state = {
      Name: '',
      userEmail: '',
      userMobile: '',
      BMDCNumber: '',
      Speciality: '',
      BioName: '',
      UserDisctrict: '',
      UserThana: '',
      UserName: '',
      Password: '',
      CPassword: ''
    }
  }
  
  validateEmail = (mail) =>
  {
   if (!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(mail))
    {
      return false;
    } else {
      return true;
    }
  }

  handleSubmit = async event => {
    event.preventDefault();
    
    const { Name, userEmail, userMobile, BMDCNumber, Speciality, BioName, UserDisctrict, UserThana, UserName, Password, CPassword } = this.state;
    if (!Speciality) {
      alert('Please fill speciality');
      return;
    }

    if (!BioName) {
      alert('Please fill professional info');
      return;
    }

    if (!UserDisctrict) {
      alert('Please fill district');
      return;
    }

    if (!UserThana) {
      alert('Please fill thana');
      return;
    }



    if (!UserName) {
      alert('Please fill username (Mobile number)');
      return;
    }
    
    if (!this.validateEmail(UserName)) {
      console.log(UserName)
      alert('Please fill valid username (Email)');
      return;
    }

    if (!Password) {
      alert('Please fill password');
      return;
    }

    if (!CPassword) {
      alert('Please fill confirm passowrd');
      return;
    }

    if (Password !==  CPassword) {
      alert('Password & Confirm password should be same.');
      return;
    }
    
    
    var dataToSend = {
      username: UserName,
      password1: Password,
      password2: CPassword,
      email: UserName,
    };
    var formBody = [];
    for (var key in dataToSend) {
      var encodedKey = encodeURIComponent(key);
      var encodedValue = encodeURIComponent(dataToSend[key]);
      formBody.push(encodedKey + '=' + encodedValue);
    }
    formBody = formBody.join('&');

    var dataToSendDR = {
      full_name: Name,
      email: UserName,
      mobile_number: userMobile,
      bmdc_number: BMDCNumber,
      speaciality: Speciality,
      bio: BioName,
      address_district: UserDisctrict,
      address_thana: UserThana,
    };
    
    
    console.log("registration: =", formBody);
    fetch(process.env.REACT_APP_BASE_API + 'auth/registration/', {
      method: 'POST',
      body: formBody,
      headers: {
        //Header Defination
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log('Reg response = ',responseJson);
        
        if (responseJson.key) {

            fetch(process.env.REACT_APP_BASE_API+'auth/user/', {
              method: 'GET',
              headers: {
                'Authorization': 'Token '+responseJson.key,
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
              },
            })
              .then((response) => response.json())
              .then((responseUserJson) => {
                console.log("User data = ", responseUserJson)

                //dorcot Data
                dataToSendDR.user = responseUserJson.id;
                var formBodyDR = [];
                for (var key in dataToSendDR) {
                  var encodedKey = encodeURIComponent(key);
                  var encodedValue = encodeURIComponent(dataToSendDR[key]);
                  formBodyDR.push(encodedKey + '=' + encodedValue);
                }
                formBodyDR = formBodyDR.join('&');

                fetch(process.env.REACT_APP_BASE_API+'api/doctor/', {
                  method: 'POST',
                  body: formBodyDR,
                  headers: {
                    'Authorization': 'Token '+responseJson.key,
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                  },
                })
                  .then((response) => response.json())
                  .then((responseDocJson) => {
                    //Hide Loader
                    console.log("Doc Saved = ", responseDocJson)
                    this.props.handleForm();
                  })
                  .catch((error) => {
                    console.error(error);
                  });

              })
              .catch((error) => {
                console.error(error);
              });

          console.log('Registration Successful. Please Login to proceed');
        } else {
          for (var key in responseJson) {
            for(let i=0; i<responseJson[key].length; i++){
              alert(responseJson[key][i]);
            }
          }
          return
        }
      })
      .catch((error) => {
        console.error(error);
      });
    
    
  }

  handleChange = event => {
    const { value, name } = event.target;
    this.setState({ [name]: value });
  }

  render() {
    const { Name, userEmail, userMobile, BMDCNumber, Speciality, BioName, UserDisctrict, UserThana, UserName, Password, CPassword } = this.state;
    
    return (
      <div className='sign-up'>
        <h2 className="title"> Registration !!</h2>
        <div className='dologin' onClick={()=> this.props.handleForm()}> Already have An Account! Log In. </div>
        
        <form className="sign-up-form" onSubmit={this.handleSubmit}>
          <FormInput type='text' name='Name' value={Name} onChange={this.handleChange} label='Enter Full Name' required />
          <FormInput type='text' name='userMobile' value={userMobile} onChange={this.handleChange} label='Enter Mobile Number' required />
          <FormInput type='text' name='BMDCNumber' value={BMDCNumber} onChange={this.handleChange} label='BMDC Number' required />
          <FormInput type='text' name='Speciality' value={Speciality} onChange={this.handleChange} label='Speciality' required />
          <FormInput type='text' name='BioName' value={BioName} onChange={this.handleChange} label='Professional Headline( e.g. MBBS)' required />
          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
            <FormInput type='text' name='UserDisctrict' value={UserDisctrict} onChange={this.handleChange} label='District' required />
            <FormInput type='text' name='UserThana' value={UserThana} onChange={this.handleChange} label='Detail Address' required />
          </div>
          
          <div style={{padding: '0 20px', fontSize: '18px'}}>
            Login
          </div>
          
          <FormInput type='email' name='UserName' value={UserName} onChange={this.handleChange} label='Email' required />
          <FormInput type='password' name='Password' value={Password} onChange={this.handleChange} label='Password' required />
          <FormInput type='password' name='CPassword' value={CPassword} onChange={this.handleChange} label='Confirm Password' required />
          <CustomButton type='submit'> Sign Up </CustomButton>
        </form>
      </div>
    )
  }
}

export default SignUp
