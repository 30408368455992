import React, { useState } from 'react';

import { CgChevronDownR, CgChevronUpR, CgLogOut, CgLastpass } from "react-icons/cg";

import logo from '../../assets/images/logo.png'
import './HeaderComponentStyle.scss';

const HeaderComponent = ({ history, docByUser }) => {
    const username = localStorage.getItem('username');
    // const bmdc = localStorage.getItem('bmdc');

    const [showModal, setShowModal] = useState(false);

    const handleLogout = () => {
        localStorage.clear();
        history.push('/');
    }

    const toggleModal = () => {
        setShowModal(!showModal);
    }
    
    const handleChangepass = () =>{
        history.push('/changepassword', );
    }

    return (
        <div className='header'>
            <div className='header-logo'>
                <div className="logo-wrapper">
                    <img src={logo} alt="" />
                </div>
            </div>
            <div className='header-content'>
                {/* <span style={{marginRight: '5px', color: docByUser.bmdc_number? 'darkgreen': 'darkred'}}> {docByUser.bmdc_number ? <AiOutlineCheckCircle/> : <AiOutlineCloseCircle/>} </span> */}
                <span style={{ color: '#000b' }}>Welcome,</span> <span style={{ margin: '0px 5px 0 15px' }}>{username}</span>
                <span className='header-icon' onClick={() => toggleModal()}> 
                {!showModal ?
                    <CgChevronDownR size={23} color='#0008' /> :
                    <CgChevronUpR size={23} color='#0008' /> 
                }
                </span>
            </div>
            {showModal ?
                <div className='header-menu-popup'>
                    <span className='header-menu-item' onClick={() => handleChangepass()}>
                        <span> Change Password</span> <span style={{ marginLeft: '10px' }}> <CgLastpass size={23} color='#0009' /> </span>
                    </span>
                    <span className='header-menu-item' onClick={() => handleLogout()}>
                        <span> LogOut</span> <span style={{ marginLeft: '10px' }}> <CgLogOut size={23} color='#0009' /> </span>
                    </span>

                </div>
                :
                null
            }
        </div>
    )
}

export default HeaderComponent
