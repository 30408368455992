import { docActionType } from './DocActionType';

const INITIAL_STATE = {
    totalDocs: [],
    todayDocs: [],
    thisWeekDocs: [],
}

const docReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case (docActionType.SET_TOTAL_DOCS):
            return {
                ...state,
                totalDocs: action.payload
            }
        case (docActionType.SET_TODAY_DOCS):
            return {
                ...state,
                todayDocs: action.payload
            }
        case (docActionType.SET_THISWEEK_DOCS):
            return {
                ...state,
                thisWeekDocs: action.payload
            }
        default: return state;
    }
}

export default docReducer;