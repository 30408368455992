import { docActionType } from "./DocActionType";

export const setTotalDocs = totalDocs =>({
    type: docActionType.SET_TOTAL_DOCS,
    payload: totalDocs,
})

export const setTodayDocs = todayDocs =>({
    type: docActionType.SET_TODAY_DOCS,
    payload: todayDocs,
})

export const setThisWeekDocs = thisWeekDocs =>({
    type: docActionType.SET_THISWEEK_DOCS,
    payload: thisWeekDocs,
})