import { rxActionType } from './RxActionType';

const INITIAL_STATE = {
    totalRxs: [],
    todayRxs: [],
    thisWeekRxs: [],
    patientsRxCount: {},
    doctorsRxCount: {},
}

const rxReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case (rxActionType.SET_TOTAL_RXS):
            return {
                ...state,
                totalRxs: action.payload
            }
        case (rxActionType.SET_TODAY_RXS):
            return {
                ...state,
                todayRxs: action.payload
            }
        case (rxActionType.SET_THISWEEK_RXS):
            return {
                ...state,
                thisWeekRxs: action.payload
            }
        case (rxActionType.SET_PATIENTS_RX_COUNT):
            return {
                ...state,
                patientsRxCount: action.payload
            }
        case (rxActionType.SET_DOCTORS_RX_COUNT):
            return {
                ...state,
                doctorsRxCount: action.payload
            }
        default: return state;
    }
}

export default rxReducer;