import moment from 'moment';
import React, { Component } from 'react'
import { connect } from 'react-redux'

import './PrescriptionDetailsStyle.scss'

export class PrescriptionDetails extends Component {
    render() {
        const { rx } = this.props;
        return (
            <div className='prescription-details'>
                <div className="rx-token"> #{rx.token} </div>
                <div className="dxs">DXs: <span>{rx.dxs}</span> </div>
                <div className="symp">Symptomps: <span>{rx.symptoms?rx.symptoms:'NA'}</span></div>
                <div className="signs">Signs: <span>{rx.signs?rx.signs:"NA"}</span></div>
                <div className="med">Medications: <span>{rx.medications?rx.medications:'NA'}</span></div>
                <div className="inves">Investigations: <span>{rx.investigations?rx.investigations:'NA'}</span></div>
                <div className="advice">Advice: <span>{rx.advice?rx.advice:'NA'}</span></div>
                <div className="followup">Followup: <span>{ rx.followup_date ? moment(rx.followup_date).format("DD MMM 'YY"):'NA' }</span></div>
                <div className="created-at">Created at: <span>{moment(rx.created_at).format("DD MMM YYYY")}</span></div>
                
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'baseline', fontSize: 10, marginTop:12 }}>
                    <span style={{margin: 0}}>Patient</span>
                    <div style={{height: '1px', backgroundColor: '#0008', flexGrow: 1, margin: '0 20px 0 5px'}}></div>
                </div>
                <div className="ptn-name">Patient's name: <span>{rx.patient.name}</span> </div>
                <div className="ptn-gender">Patient's gender: <span>{rx.patient.gender? rx.patient.gender: 'NA'}</span></div>
                <div className="ptn-age">Patient's age: <span>{rx.patient.age} years</span></div>
                <div className="ptn-mobile">Patient's mobile: <span>{rx.patient.mobile}</span> </div>
                <div className="ptn-occu">Patient's occupation: <span>{rx.patient.occupation ? rx.patient.occupation : 'NA'}</span> </div>
                
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'baseline', fontSize: 10, marginTop:12 }}>
                    <span style={{margin: 0}}>Doctor</span>
                    <div style={{height: '1px', backgroundColor: '#0008', flexGrow: 1, margin: '0 20px 0 5px'}}></div>
                </div>
                <div className="doc-name">Doctor's name: <span>{rx.doctor.bmdc_number?rx.doctor.title:''} {rx.doctor.full_name}</span></div>
                <div className="doc-sp">Doctor's speciality: <span>{rx.doctor.speaciality?rx.doctor.speaciality:'NA'}</span></div>
                <div className="doc-bmdc">BMDC: <span>{rx.doctor.bmdc_number?rx.doctor.bmdc_number:'NA'}</span></div>
                <div className="doc-email">Doctor's email: <span>{rx.doctor.email?rx.doctor.email:'NA'}</span></div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    
})

const mapDispatchToProps = {
    
}

export default connect(mapStateToProps, mapDispatchToProps)(PrescriptionDetails)
