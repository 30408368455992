
import { connect } from 'react-redux';
import DoctorCard from './DoctorCard';

import './DoctorsListStyle.scss';

const DoctorsList = ({ showDocs, changeState }) => {
    // console.log('docs --',showDocs);
    
    return (
        <div className='doclist'>
            <div className='listHead'>
                <div className='title'>
                    Doctors Lists
                </div>
            </div>
            <div className='allLists'>
                {showDocs.map((doc, indx) => <DoctorCard key={indx} doc={doc} changeState={(changeableObj) => changeState(changeableObj)}/> )}
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    showDocs: state.showDatas.showDocs,
})

const mapDispatchToProps = dispatch =>({
    
})


export default connect(mapStateToProps, mapDispatchToProps)(DoctorsList);
