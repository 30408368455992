import { createStore, applyMiddleware } from "redux";
import logger from "redux-logger";

import RootReducer from "./RootReducer";

const middleWares = [];

if (process.env.NODE_ENV === 'development') {
    middleWares.push(logger);
}

const store = createStore(RootReducer, applyMiddleware(...middleWares));

export default store;