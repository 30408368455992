import { combineReducers } from "redux";

import rxReducer from './rx/RxReducer';
import docReducer from './doc/DocReducer';
import ptReducer from './pt/PtReducer';
import showDataReducer from './showData/showDataReducer';
import drawerReducer from './drawer/DrawerReducer'

export default combineReducers({
    rxs: rxReducer,
    docs: docReducer,
    pts: ptReducer,
    showDatas: showDataReducer,
    drawer: drawerReducer,
})