import { showDataActionType } from './showDataActionType';

const INITIAL_STATE = {
    showRxs: [],
    showDocs: [],
    showPts: [],
    showRxsSubtitle: ''
}

const showDataReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case (showDataActionType.SET_SHOW_RXS):
            return {
                ...state,
                showRxs: action.payload
            }
        case (showDataActionType.SET_SHOW_DOCS):
            return {
                ...state,
                showDocs: action.payload
            }
        case (showDataActionType.SET_SHOW_PTS):
            return {
                ...state,
                showPts: action.payload
            }
        case (showDataActionType.SET_SHOW_RX_SUBTITLE):
            return {
                ...state,
                showRxsSubtitle: action.payload
            }
        default: return state;
    }
}

export default showDataReducer;