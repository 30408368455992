import React, { Component } from 'react';

import FormInput from '../form-input/FormInput.component';
import CustomButton from '../custom-button/CustomButton.component';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './ChangePasswordStyle.scss';

export class ChangePassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            newPassword: '',
            confirmPassword: '',
        }
    }

    notifyinfo = (message) => toast.info(message, {
        position: "top-right",
        autoClose: 4500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
    });

    notifyerr = (message) => toast.error(message, {
        position: "top-right",
        autoClose: 4500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
    });

    notifysuccess = (message) => {
        toast.success(message, {
            position: "top-right",
            autoClose: 4500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
        });

    }




    handleSubmit = async event => {
        event.preventDefault();

        const { newPassword, confirmPassword } = this.state;

        if (!newPassword) {
            this.notifyerr('Please fill New Password.');
            return;
        }

        if (!confirmPassword) {
            this.notifyerr('Please fill Confirm Password');
            return;
        }
        
        if (newPassword !== confirmPassword) {
            this.notifyerr('New password & Confirm password should be same.');
            return;
        }

        if (newPassword.length < 8) {
            console.log('fine');

            this.notifyerr('Password must have atleast 8 charecters');
            return;
        }

        

        let key = localStorage.getItem('key')
        let id = localStorage.getItem('id')
        fetch(process.env.REACT_APP_BASE_API + 'api/change-password/', {
            method: 'POST',
            body: JSON.stringify(
                {
                    user_id: id,
                    password: newPassword
                }
            ),
            headers: {
                'Authorization': 'Token ' + key,
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
            },
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log(responseJson);
                this.setState({ newPassword: '', confirmPassword: '', });
                this.notifysuccess("Password changed successfully! Redirecting to dashboard");
                setTimeout(() => this.props.history.push('/overview'), 2000);
                
                // this.props.history.push('/overview');

            })
            .catch((error) => {
                this.notifyerr(error.message);
                console.error(error);
            });


    }

    handleChange = event => {
        const { value, name } = event.target;
        this.setState({ [name]: value });
    }


    componentDidMount() {
        if (!localStorage.getItem('key') || !localStorage.getItem('id') || !localStorage.getItem('is_superuser')) {
            this.props.history.push('/');
        }
        // console.log(localStorage.getItem('id'));
    }


    render() {
        return (

            <div className='authentication'>

                <div className="headTitle">
                    E-Laj DashBoard (Amarlab.com)
                </div>

                <div className="wrapper">
                    <div className='changepass'>
                        <h2 className='title'> Change Password </h2>
                        <div className='dashback' onClick={() => this.props.history.push('/overview')}> Go Back to dahsboard. </div>

                        <form onSubmit={this.handleSubmit}>
                            <FormInput type="password" label="New Password" name="newPassword" value={this.state.newPassword} handleChange={this.handleChange} />

                            <FormInput type="password" label="Confirm Password" name="confirmPassword" value={this.state.confirmPassword} handleChange={this.handleChange} />

                            <div className="button-box">
                                <CustomButton type="submit"> Change Password </CustomButton>
                            </div>

                        </form>
                    </div>
                </div>


                <ToastContainer />
            </div>
        )
    }
}

export default ChangePassword
