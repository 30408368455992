import React, { Component } from 'react';
import { connect } from 'react-redux';

import DrawerComponent from '../../components/Drawer/DrawerComponent';
import HeaderComponent from '../../components/header/HeaderComponent';
import OverviewComponent from '../../components/overview/OverviewComponent';

import { setTotalRx, setTodayRx, setThisWeekRx, setPatientsRxCount, setDoctorsRxCount } from '../../redux/rx/RxAction';
import { setShowDocs, setShowPts, setShowRxs } from '../../redux/showData/showDataAction';
import { setTotalPts, setThisWeekPts, setTodayPts } from '../../redux/pt/PtAction';
import { setTotalDocs, setTodayDocs, setThisWeekDocs } from '../../redux/doc/DocAction';
import { setSelectedDrawerMenu } from '../../redux/drawer/DrawerAction';

import './OverviewPageStyle.scss';

export class OverviewPage extends Component {


    countingData = (responseData) => {

        let totalptFilter = {};

        let totalValidRx = [];

        let thisWeekPtFilter = {};
        let thisWeekDocFilter = {};

        let todayPtFilter = {};
        let todayDocFilter = {};

        let todayRxsTemp = [];
        let thisWeekRxsTemp = [];

        let doctorsRxCount = {};
        let patientsRxCount = {};


        let today = new Date();
        let thisweek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);


        for (const rx of responseData) {

            if (rx && rx.patient && rx.doctor) {
                totalValidRx.push(rx);

                let rxdate = new Date(rx.created_at);
                if (rxdate.getDate() === today.getDate() && rxdate.getMonth() === today.getMonth() && rxdate.getFullYear() === today.getFullYear()) {
                    todayRxsTemp.push(rx);
                }
                if (thisweek <= rxdate) {
                    thisWeekRxsTemp.push(rx);
                }


                totalptFilter[rx.patient.id] = rx.patient;

                let ptdate = new Date(rx.patient.created_at);
                if (ptdate.getDate() === today.getDate() && ptdate.getMonth() === today.getMonth() && ptdate.getFullYear() === today.getFullYear()) {
                    todayPtFilter[rx.patient.id] = rx.patient;
                }
                if (thisweek <= ptdate) {
                    thisWeekPtFilter[rx.patient.id] = rx.patient;

                }




                let docdate = new Date(rx.doctor.created_at);
                if (docdate.getDate() === today.getDate() && docdate.getMonth() === today.getMonth() && docdate.getFullYear() === today.getFullYear()) {
                    todayDocFilter[rx.doctor.user.id] = rx.doctor;
                }
                if (thisweek <= docdate) {
                    thisWeekDocFilter[rx.doctor.user.id] = rx.doctor;
                }



                // rx counting for doctor

                if (!doctorsRxCount['' + rx.doctor.user.id]) {
                    let newObj = { ...doctorsRxCount };
                    newObj['' + rx.doctor.user.id] = [rx];

                    doctorsRxCount = newObj;
                }
                else {
                    let val = doctorsRxCount['' + rx.doctor.user.id];
                    val.push(rx);
                    let newObj = { ...doctorsRxCount };
                    newObj['' + rx.doctor.user.id] = val;

                    doctorsRxCount = newObj;

                }


                // rx counting for patients

                if (!patientsRxCount['' + rx.patient.id]) {
                    let newObj = { ...patientsRxCount };
                    newObj['' + rx.patient.id] = [rx];

                    patientsRxCount = newObj;
                }
                else {
                    let val = patientsRxCount['' + rx.patient.id];
                    val.push(rx);
                    let newObj = { ...patientsRxCount };
                    newObj['' + rx.patient.id] = val;

                    patientsRxCount = newObj;
                }
            }

        }

        this.props.setTotalRx(totalValidRx);
        this.props.setShowRxs(totalValidRx);

        this.props.setPatientsRxCount(patientsRxCount);
        this.props.setDoctorsRxCount(doctorsRxCount);

        this.props.setTodayRx(todayRxsTemp);
        this.props.setTodayPts(Object.values(todayPtFilter));
        this.props.setTodayDocs(Object.values(todayDocFilter));

        this.props.setThisWeekRx(thisWeekRxsTemp);
        this.props.setThisWeekPts(Object.values(thisWeekPtFilter));
        this.props.setThisWeekDocs(Object.values(thisWeekDocFilter));


        this.props.setTotalPts(Object.values(totalptFilter));
        this.props.setShowPts(Object.values(totalptFilter));
    }


    fetchAllDoctors = async () => {
        console.log(process.env.REACT_APP_BASE_API + 'api/doctor/');
        let token = localStorage.getItem('key');
        let response = await fetch(process.env.REACT_APP_BASE_API + 'api/doctor/', {
            method: 'GET',
            headers: {
                'Authorization': 'Token ' + token,
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
            },
        }).catch((error) => {
            console.error(error);
        });

        let responseData = await response.json();
        this.props.setTotalDocs(responseData);
        this.props.setShowDocs(responseData);
    }


    // fetching rx
    fetchRx = () => {
        console.log(process.env.REACT_APP_BASE_API + 'api/prescription-all/');
        let token = localStorage.getItem('key');
        fetch(process.env.REACT_APP_BASE_API + 'api/prescription-all/', {
            method: 'GET',
            headers: {
                'Authorization': 'Token ' + token,
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
            },
        })
            .then((response) => response.json())
            .then((responseData) => {

                this.props.setTotalRx(responseData);
                // console.log('rx list fetched ', responseData);

                this.countingData(responseData);
                this.fetchAllDoctors();
            })
            .catch((error) => {
                console.error(error);
            });
    }



    componentDidMount() {
        this.props.setSelectedDrawerMenu('ov');

        if (!localStorage.getItem('key') || !localStorage.getItem('is_superuser')) {
            this.props.history.push('/');
        } else {
            if (!this.props.totalRxs.length) {
                this.fetchRx();
            }
        }
        // console.log(this.props.totalRxs);
    }

    render() {
        return (
            <div className='overview-page'>
                <div className='header-box'>
                    <HeaderComponent docByUser={{}} history={this.props.history} />
                </div>
                <div className='container'>
                    <DrawerComponent />
                    <OverviewComponent history={this.props.history} />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    totalRxs: state.rxs.totalRxs,
})

const mapDispatchToProps = dispatch => ({
    setTotalRx: totalRxs => dispatch(setTotalRx(totalRxs)),
    setTodayRx: todayRxs => dispatch(setTodayRx(todayRxs)),
    setThisWeekRx: thisWeekRxs => dispatch(setThisWeekRx(thisWeekRxs)),
    setPatientsRxCount: patientsRxCount => dispatch(setPatientsRxCount(patientsRxCount)),
    setDoctorsRxCount: doctorsRxCount => dispatch(setDoctorsRxCount(doctorsRxCount)),
    setTotalPts: totalPts => dispatch(setTotalPts(totalPts)),
    setTodayPts: todayPts => dispatch(setTodayPts(todayPts)),
    setThisWeekPts: thisWeekPts => dispatch(setThisWeekPts(thisWeekPts)),
    setTotalDocs: totalDocs => dispatch(setTotalDocs(totalDocs)),
    setTodayDocs: todayDocs => dispatch(setTodayDocs(todayDocs)),
    setThisWeekDocs: thisWeekDocs => dispatch(setThisWeekDocs(thisWeekDocs)),
    setSelectedDrawerMenu: selectedMenu => dispatch(setSelectedDrawerMenu(selectedMenu)),
    setShowRxs: showRxs => dispatch(setShowRxs(showRxs)),
    setShowPts: showPts => dispatch(setShowPts(showPts)),
    setShowDocs: showDocs => dispatch(setShowDocs(showDocs)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OverviewPage);
