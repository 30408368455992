import React, { Component } from 'react';
import { FaPrescription, FaUserMd, FaUserInjured } from "react-icons/fa";
import moment from 'moment';
import { connect } from 'react-redux';

import OverviewCard from './OverviewCard';

import { setShowDocs, setShowRxs, setShowPts } from '../../redux/showData/showDataAction';

import './OverviewStyle.scss';

export class OverviewComponent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            token: localStorage.getItem('key'),

        }
    }


    menuAndShowRxHandle = (dataToShow) => {
        this.props.setShowRxs(dataToShow);
        this.props.history.push('/prescriptions')
    }
    menuAndShowDocHandle = (dataToShow) => {
        this.props.setShowDocs(dataToShow);
        this.props.history.push('/doctors')
    }
    menuAndShowPtHandle = (dataToShow) => {
        this.props.history.push('/patients')
        this.props.setShowPts(dataToShow );
    }


    componentDidMount() {
        
    }

    render() {

        // const {totalRxs,totalDocs,totalPts,todayRxs,todayDocs,todayPts,thisWeekRxs,thisWeekDocs,thisWeekPts} = this.props.stateVal;

        return (
            <div className='overviewContainer'>
                <div className="rowSec">
                    <OverviewCard icon={<FaPrescription />} title='Total Prescriptions' counter={this.props.totalRxs.length} onClickRun={()=> this.menuAndShowRxHandle(this.props.totalRxs)} />
                    <OverviewCard icon={<FaUserMd />} style={{ backgroundColor: '#e0f0ff' }} title='Total Doctors' counter={this.props.totalDocs.length} onClickRun={()=> this.menuAndShowDocHandle(this.props.totalDocs)} />
                    <OverviewCard icon={<FaUserInjured />} style={{ backgroundColor: '#fff2df' }} title='Total Patients' counter={this.props.totalPts.length} onClickRun={()=> this.menuAndShowPtHandle(this.props.totalPts)}  />
                </div>
                <div className="rowSec">
                    <span>------ Weekly ------</span>
                </div>
                <div className="rowSec">
                    <OverviewCard icon={<FaPrescription/>} title='Prescriptions' counter={this.props.thisWeekRxs.length} onClickRun={()=> this.menuAndShowRxHandle(this.props.thisWeekRxs)} />
                <OverviewCard icon={<FaUserMd/>} style={{backgroundColor: '#e0f0ff'}} title='Doctors' counter={this.props.thisWeekDocs.length} onClickRun={()=> this.menuAndShowDocHandle(this.props.thisWeekDocs)} />
                <OverviewCard icon={<FaUserInjured/>} style={{backgroundColor: '#fff2df'}} title='Patients' counter={this.props.thisWeekPts.length} onClickRun={()=> this.menuAndShowPtHandle(this.props.thisWeekPts)}/>
                </div>
                <div className="rowSec">
                    <span>--------- Today({moment().format('DD MMM YYYY')}) --------- </span>
                </div>
                <div className="rowSec">
                    <OverviewCard icon={<FaPrescription/>} title='Prescriptions' counter={this.props.todayRxs.length} onClickRun={()=> this.menuAndShowRxHandle(this.props.todayRxs)} />
                <OverviewCard icon={<FaUserMd/>} style={{backgroundColor: '#e0f0ff'}} title='Doctors' counter={this.props.todayDocs.length} onClickRun={()=> this.menuAndShowDocHandle(this.props.todayDocs)} />
                <OverviewCard icon={<FaUserInjured/>} style={{backgroundColor: '#fff2df'}} title='Patients' counter={this.props.todayPts.length} onClickRun={()=> this.menuAndShowPtHandle(this.props.todayPts)}/>
                </div>

            </div>
        )
    }
}


const mapStateToProps = (state) => ({
    totalRxs: state.rxs.totalRxs,
    todayRxs: state.rxs.todayRxs,
    thisWeekRxs: state.rxs.thisWeekRxs,
    totalDocs: state.docs.totalDocs,
    todayDocs: state.docs.todayDocs,
    thisWeekDocs: state.docs.thisWeekDocs,
    totalPts: state.pts.totalPts,
    todayPts: state.pts.todayPts,
    thisWeekPts: state.pts.thisWeekPts,
})

const mapDispatchToProps = dispatch =>({
    setShowDocs: showdocs => dispatch(setShowDocs(showdocs)),
    setShowPts: showdocs => dispatch(setShowPts(showdocs)),
    setShowRxs: showdocs => dispatch(setShowRxs(showdocs)),
})

export default connect(mapStateToProps, mapDispatchToProps)(OverviewComponent);
