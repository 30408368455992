import React from 'react';
import './FormInput.style.scss';

const FormInput = ({handleChange, style, label, ...otherProps}) => {
    return (
        <div className="form-input-wrapper" style={style}>
            <input className="form-input" onChange={handleChange} {...otherProps}/>
            {label?
                (<label className={`${otherProps.value.length? 'shrink':''} form-input-label`} > {label} </label>)
            :null }
        </div>
    )
}

export default FormInput
