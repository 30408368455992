import { rxActionType } from "./RxActionType";

export const setTotalRx = totalRxs =>({
    type: rxActionType.SET_TOTAL_RXS,
    payload: totalRxs,
})

export const setTodayRx = todayRxs =>({
    type: rxActionType.SET_TODAY_RXS,
    payload: todayRxs,
})

export const setThisWeekRx = thisWeekRxs =>({
    type: rxActionType.SET_THISWEEK_RXS,
    payload: thisWeekRxs,
})

export const setPatientsRxCount = patientsRxCount =>({
    type: rxActionType.SET_PATIENTS_RX_COUNT,
    payload: patientsRxCount,
})

export const setDoctorsRxCount = doctorsRxCount =>({
    type: rxActionType.SET_DOCTORS_RX_COUNT,
    payload: doctorsRxCount,
})