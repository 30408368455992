import { drawerActionType } from './DrawerActionType';

const INITIAL_STATE = {
    isDrawerOpen: false,
    selectedMenu: 'ov',
}

const drawerReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case (drawerActionType.SET_DRAWER_TOOGLE):
            return {
                ...state,
                isDrawerOpen: action.payload
            }
        case (drawerActionType.SET_SELECTED_DRAWER_MENU):
            return {
                ...state,
                selectedMenu: action.payload
            }
        default: return state;
    }
}

export default drawerReducer;