import React, { Component } from 'react'
import { connect } from 'react-redux'


import DrawerComponent from '../../components/Drawer/DrawerComponent'
import HeaderComponent from '../../components/header/HeaderComponent'
import PrescriptionList from '../../components/Prescription/PrescriptionList'


import { setShowRxs, setShowRxsSubtitle } from '../../redux/showData/showDataAction'
import { setSelectedDrawerMenu } from '../../redux/drawer/DrawerAction';


import './PrescriptionsPageStyle.scss'

export class PrescriptionsPage extends Component {
    
    componentDidMount(){
        this.props.setSelectedDrawerMenu('rx');
        
        const {pid, did} = this.props.match.params;
        // console.log(this.props.match);
        
        if (pid) {
            // console.log(this.props.patientsRxCount[pid]);
            this.props.setShowRxsSubtitle(this.props.patientsRxCount[pid] ? 'For Pt. ' + this.props.patientsRxCount[pid][0].patient.name : '' );
            this.props.setShowRxs(this.props.patientsRxCount[pid] ? this.props.patientsRxCount[pid] : [])
        }
        else if (did){
            // console.log(this.props.doctorsRxCount[did]);
            this.props.setShowRxsSubtitle(this.props.doctorsRxCount[did] ? 'For Dr. ' + this.props.doctorsRxCount[did][0].doctor.full_name : '' );
            this.props.setShowRxs(this.props.doctorsRxCount[did] ? this.props.doctorsRxCount[did] : [])
        }
        else{
            // this.props.setShowRxs(this.props.totalRxs)
            this.props.setShowRxsSubtitle( '' );
        }
    }
    
    componentDidUpdate(){
        const {pid, did} = this.props.match.params;
        
        if (pid) {
            console.log('----pid-----');
            this.props.setShowRxsSubtitle(this.props.patientsRxCount[pid] ? 'For Pt. ' + this.props.patientsRxCount[pid][0].patient.name : '' );
            this.props.setShowRxs(this.props.patientsRxCount[pid] ? this.props.patientsRxCount[pid] : [])
        }
        else if (did){
            console.log('----did-----');
            this.props.setShowRxsSubtitle(this.props.doctorsRxCount[did] ? 'For Dr. ' + this.props.doctorsRxCount[did][0].doctor.full_name : '' );
            this.props.setShowRxs(this.props.doctorsRxCount[did] ? this.props.doctorsRxCount[did] : [])
        }
        else{
            this.props.setShowRxsSubtitle( '' );
        }
    }
    
    render() {
        // console.log(this.props.totalRxs);
        return (
            <div className='prescriptions-page'>
                <div className='header-box'>
                    <HeaderComponent docByUser={{}} history={this.props.history} />
                </div>
                <div className='container'>
                    <DrawerComponent />
                    <PrescriptionList subtitle={this.subtitle} />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    totalRxs: state.rxs.totalRxs,
    todayRxs: state.rxs.todayRxs,
    thisWeekRxs: state.rxs.thisWeekRxs,
    totalDocs: state.docs.totalDocs,
    todayDocs: state.docs.todayDocs,
    thisWeekDocs: state.docs.thisWeekDocs,
    totalPts: state.pts.totalPts,
    todayPts: state.pts.todayPts,
    thisWeekPts: state.pts.thisWeekPts,
    patientsRxCount: state.rxs.patientsRxCount,
    doctorsRxCount: state.rxs.doctorsRxCount,
})

const mapDispatchToProps = dispatch =>({
    setShowRxs: showRxs => dispatch(setShowRxs(showRxs)),
    setShowRxsSubtitle: showRxsSubtitle => dispatch(setShowRxsSubtitle(showRxsSubtitle)),
    setSelectedDrawerMenu: selectedMenu => dispatch(setSelectedDrawerMenu(selectedMenu)),
    
})

export default connect(mapStateToProps, mapDispatchToProps)(PrescriptionsPage)
