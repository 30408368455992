import moment from 'moment';
import React, { useState } from 'react';
import { connect } from 'react-redux';
// import { FaTimes } from "react-icons/fa";
import { FaUserInjured } from "react-icons/fa";
// import { BiMessageSquareCheck, BiMessageSquareX } from "react-icons/bi";
import { useHistory } from 'react-router';


import './PatienetCardStyle.scss';

const PatienetCard = ({ patientsRxCount, pt, style, changeState }) => {

    const [modalVisiblity, setModalVisiblity] = useState(false);
    const [isDocModal, setIsDocModal] = useState(true);
    const history = useHistory();

    const toggleModal = () => {
        setModalVisiblity(!modalVisiblity)
    }

    const modalValHandle = (val) => {
        setIsDocModal(val);
        toggleModal();
    }

    const openInNewTab= (id) => {
        window.open(process.env.REACT_APP_BASE_API + 'api/rx/' + id, '_blank').focus();
    }
    
    const menuAndShowRxHandle = ( id) => {
        history.push('/prescriptions/pid/'+id);
    }
    
    
    
    

    return (
        <div className='ptnCard' style={{cursor: 'pointer'}} onClick={()=> menuAndShowRxHandle(pt.id)} >
            <div className='frstSec'>
                <div className="imgWrapper" >
                    { <FaUserInjured size={50} />} 
                </div>
                <div className='details'>
                    
                    <div  className='items' style={{ fontSize: 16, minWidth: '100%',}} >
                        <span style={{color: '#0a609e', maxWidth: '190px', maxHeight: '25px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
                            {pt.name}
                        </span> 
                    </div>
                    
                    <div className='items' style={{color: '#000'}}>
                        {pt.gender}
                    </div>
                    <div className='items' style={{fontWeight: 'bold'}}>
                        Occupation: <div>{pt.occupation? pt.occupation: 'Not Found'}</div>
                    </div>
                    <div className='items' style={{fontWeight: 'bold'}}>
                        Doctor: <div>{pt.doctor.full_name}</div>
                    </div>
                    
                </div>
            </div>
            <div className='scndSec'>
                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                        <div className='items'>
                            DOB: <div>{moment(pt.dob).format("DD MMM  'YY")} </div>
                        </div>
                        <div className='items'>
                            Mobile: <div>{pt.mobile}</div>
                        </div>
                    </div>
                    <div className='items'>
                        Address: <div>{pt.address_district}</div>
                    </div>
                    <div className='items' >
                        RXs: <div>{patientsRxCount[''+pt.id].length}+</div>
                    </div>
                    
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    patientsRxCount: state.rxs.patientsRxCount
})

const mapDispatchToProps = dispatch => ({
    
})

export default connect(mapStateToProps, mapDispatchToProps)(PatienetCard);
