import React from 'react';

import './OverviewCardStyle.scss';

const OverviewCard = ({title, counter, style, icon, onClickRun}) => {
    return (
        <div style={style} className='ovCardWrapper' onClick={onClickRun}>
            <div className="innerWrapper">
                <div className='cardIcon'>
                    {icon}
                </div>
                
                <div className='title'>
                    {title}
                </div>
                <div className='counter'>
                    {counter}
                </div>
            </div>
        </div>
    )
}

export default OverviewCard
