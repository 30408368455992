import { ptActionType } from "./PtActionType";

export const setTotalPts = totalPts =>({
    type: ptActionType.SET_TOTAL_PTS,
    payload: totalPts,
})

export const setTodayPts = todayPts =>({
    type: ptActionType.SET_TODAY_PTS,
    payload: todayPts,
})

export const setThisWeekPts = thisWeekPts =>({
    type: ptActionType.SET_THISWEEK_PTS,
    payload: thisWeekPts,
})