
import moment from 'moment';
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';
import { DateRangePicker } from 'rsuite';
import 'rsuite/dist/styles/rsuite-default.css';

// import ReactPaginate from 'react-paginate';

import PrescriptionCard from './PrescriptionCard';


import './PrescriptionListStyle.scss';



export class PrescriptionList extends Component {
    
    constructor(props){
        super(props);
        this.state={
            dateFilterArray: []
        }
    }
    
    // let pageNo = 1;
    // const offset = 12;

    // const pageCount = Math.ceil(showrxs.length / offset);

    // const calcNewData = () => {

    //     let startIndx = offset * (pageNo - 1);
    //     console.log('object');

    //     return showrxs.slice(startIndx, startIndx + offset)

    // }

    // const [storeToShow, setStoreToShow] = useState(calcNewData());


    // const handlePageClick = (data) => {
    //     console.log('data: ',data);
    //     pageNo = data.selected + 1;
    //     console.log('page ', pageNo)
    //     calcNewData();
    //     setStoreToShow(calcNewData());
    // }
    
    handleDateFilterChange(val){
        this.setState({dateFilterArray: val});
    }
    
    
    componentDidMount(){
        // console.log(this.props);
    }

    render() {
        const{showRxs} = this.props;
        const { dateFilterArray } = this.state;
        return (
            <div className='rxlist'>
                <div className='listHead'>
                    <div className='title'>
                        Prescription Lists
                    </div>
                </div>
                <div className='rxfilter'>
                    <div className='rxfilter-sec'>
                        <DateRangePicker
                            appearance="default"
                            placeholder="Filter by date"
                            style={{ width: 250 }}
                            onChange={(val) => this.handleDateFilterChange(val)}
                        />
                    </div>
                    <div className='rxfilter-sec'>
                        {this.props.showRxsSubtitle}
                    </div>
                    <div className='rxfilter-sec'>
                    </div>
                </div>
                <div className='allLists'>
                    {/* {storeToShow.map((rx, indx) => <PrescriptionCard key={indx} rxcount={patientsRxCount} rx={rx} />)} */}
                    {!dateFilterArray.length ? 
                        showRxs.map((rx, indx) => <PrescriptionCard key={indx} rx={rx} />)
                    :
                        showRxs.map((rx, indx) => {
                            let rxdate = new Date(moment(rx.created_at).format('DD MMM YYYY'));
                            let filterStartDate = new Date(moment(dateFilterArray[0]).format('DD MMM YYYY'));
                            let filterFinishDate = new Date(moment(dateFilterArray[1]).format('DD MMM YYYY'));
                            return(
                                (rxdate >= filterStartDate &&  rxdate <=filterFinishDate) ? <PrescriptionCard key={indx} rx={rx} /> : null
                            )
                        })
                    
                    }
                </div>
                {/* <div id='react-paginate'>
                <ReactPaginate
                    previousLabel={'previous'}
                    nextLabel={'next'}
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageClick}
                    containerClassName={'pagination'}
                    activeClassName={'active'}
                />
            </div> */}

            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    showRxs: state.showDatas.showRxs,
    showRxsSubtitle: state.showDatas.showRxsSubtitle,
})

const mapDispatchToProps = dispatch => ({

})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PrescriptionList));
