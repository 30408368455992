import React, { Component } from 'react'
import { connect } from 'react-redux'
import DrawerComponent from '../../components/Drawer/DrawerComponent'
import HeaderComponent from '../../components/header/HeaderComponent'
import PatienetsList from '../../components/Patienets/PatienetsList'

import { setSelectedDrawerMenu } from '../../redux/drawer/DrawerAction';

import './PatientsPageStyle.scss'

export class PatientsPage extends Component {
    
    componentDidMount(){
        this.props.setSelectedDrawerMenu('pt');
        
    }
    
    render() {
        return (
            <div className='patients-page'>
                <div className='header-box'>
                    <HeaderComponent docByUser={{}} history={this.props.history} />
                </div>
                <div className='container'>
                    <DrawerComponent />
                    <PatienetsList />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    
})

const mapDispatchToProps = dispatch =>({
    setSelectedDrawerMenu: selectedMenu => dispatch(setSelectedDrawerMenu(selectedMenu)),
    
})

export default connect(mapStateToProps, mapDispatchToProps)(PatientsPage)
