import React, { Component } from 'react';

import SignIn from '../../components/signIn/SignIn.component';
import SignUp from '../../components/signUp/SignUp.component';

import './AuthPageStyle.scss';

export class AuthPage extends Component {
    constructor(props){
        super(props);
        this.state = {
            isAccount: true
        }
        this.unblock = this.props.history.block();
    }
    
    componentDidMount(){
        // console.log(this.props.history);
        // this.unblock = this.props.history.block();
    }
    
    render() {
        
        const { history, changePassword } = this.props;
        
        const {isAccount} = this.state;
        return (
            <div className='authentication'>
                <div className="headTitle">
                    E-Laj DashBoard (Amarlab.com)
                </div>
                <div className="wrapper">
                    {
                    isAccount?
                        <SignIn history={history} unblock={()=>this.unblock()} handleForm={()=> this.setState({isAccount: false})}/> :
                        <SignUp handleForm={()=> this.setState({isAccount: true})}/>
                    }
                </div>
                {/* <SignUp /> */}
            </div>
        )
    }
}

export default AuthPage
