import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { connect } from 'react-redux';

import FormInput from '../form-input/FormInput.component';
import CustomButton from '../custom-button/CustomButton.component';

import './SignIn.style.scss';

export class SignIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userName: '',
      userPassword: '',
    }
  }

  
  notifyinfo = (message) => toast.info(message, {
    position: "top-right",
    autoClose: 4500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
});

notifyerr = (message) => toast.error(message, {
    position: "top-right",
    autoClose: 4500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
});

notifysuccess = (message) => {
    toast.success(message, {
        position: "top-right",
        autoClose: 4500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
    });

}
  
  
  


  fetchUser = (key, didlogin) => {
    this.props.unblock();
    
    fetch(process.env.REACT_APP_BASE_API + 'api/user/', {
      method: 'GET',
      headers: {
        'Authorization': 'Token ' + key,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((responseUserJson) => {
        // console.log(responseUserJson);
        if (responseUserJson.is_superuser) {
          localStorage.setItem('email', responseUserJson.email);
          localStorage.setItem('username', responseUserJson.username);
          localStorage.setItem('id', responseUserJson.id);
          localStorage.setItem('is_superuser', responseUserJson.is_superuser);
          localStorage.setItem('user_data', responseUserJson);
          
          if (didlogin) {
            this.notifysuccess("Log in successful! Redirecting to dashboard.");
            setTimeout(() => this.props.history.push('/overview'), 2000);
          } else {
            this.props.history.push('/overview');
          }
          // this.props.history.push('/overview');
          
          
        } else {
          this.notifyerr("You are not authorized to access. Please try again !");
          this.setState({userName: '', userPassword: ''});
          localStorage.clear();
        }
        
      })
      .catch((error) => console.log(error))
  }




  handleSubmit = async event => {
    event.preventDefault();

    const { userName, userPassword } = this.state;
    if (!userName) {
      this.notifyerr('Please fill UserName.');
      return;
    }

    if (!userPassword) {
      this.notifyerr('Please fill password');
      return;
    }



    let dataToSend = { username: userName, password: userPassword };
    let formBody = JSON.stringify(dataToSend);
    console.log('formdata', formBody);

    // let formBody = [];
    // for (let key in dataToSend) {
    //   let encodedKey = encodeURIComponent(key);
    //   let encodedValue = encodeURIComponent(dataToSend[key]);
    //   formBody.push(encodedKey + '=' + encodedValue);
    // }
    // formBody = formBody.join('&');


    fetch(process.env.REACT_APP_BASE_API+ 'auth/login/', {
      method: 'POST',
      body: formBody,
      headers: {
        //Header Defination
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log('The login response is = ', responseJson);
        if (responseJson.key) {
          
          localStorage.setItem('key', responseJson.key);

          this.fetchUser(responseJson.key, true);

          // this.props.history.push('/dashboard');
        } else {
          this.notifyerr('User not found, Please check your username and password');
        }
      })
      .catch((error) => {
        console.error(error.type, error.message);
        this.notifyerr(error.message);
      })

  }

  handleChange = event => {
    const { value, name } = event.target;
    this.setState({ [name]: value });
  }


  componentDidMount() {
    let key = localStorage.getItem('key');

    if (key)
      this.fetchUser(key, false);

  }


  render() {
    return (
      <div className='sign-in'>
        <h2 className='title'> Sign In !! </h2>
        {/* <div className='createacc' onClick={() => this.props.handleForm()}> Dont Have An Account ! Create one. </div> */}

        <form onSubmit={this.handleSubmit}>
          <FormInput type="text" label="Username" name="userName" value={this.state.userName} handleChange={this.handleChange} />
          <FormInput type="password" label="Password" name="userPassword" value={this.state.userPassword} handleChange={this.handleChange} />

          <div className="button-box">
            <CustomButton type="submit"> Sign In </CustomButton>
          </div>

        </form>
        <ToastContainer />
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  totalRxs: state.rxs.totalRxs,
})

const mapDispatchToProps = dispatch => ({
  // setTotalRx: totalRxs => dispatch(setTotalRx(totalRxs)),
  // setTodayRx: todayRxs => dispatch(setTodayRx(todayRxs)),
  // setThisWeekRx: thisWeekRxs => dispatch(setThisWeekRx(thisWeekRxs)),
  // setPatientsRxCount: patientsRxCount => dispatch(setPatientsRxCount(patientsRxCount)),
  // setDoctorsRxCount: doctorsRxCount => dispatch(setDoctorsRxCount(doctorsRxCount)),
  // setTotalPts: totalPts => dispatch(setTotalPts(totalPts)),
  // setTodayPts: todayPts => dispatch(setTodayPts(todayPts)),
  // setThisWeekPts: thisWeekPts => dispatch(setThisWeekPts(thisWeekPts)),
  // setTotalDocs: totalDocs => dispatch(setTotalDocs(totalDocs)),
  // setTodayDocs: todayDocs => dispatch(setTodayDocs(todayDocs)),
  // setThisWeekDocs: thisWeekDocs => dispatch(setThisWeekDocs(thisWeekDocs)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
