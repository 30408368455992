import React, { useState } from 'react';
// import { FaTimes } from "react-icons/fa";
import { FaUserMd, } from "react-icons/fa";
import { BiMessageSquareCheck, BiMessageSquareX } from "react-icons/bi";
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom'

import './DoctorCardStyle.scss';

const DoctorCard = ({ doc, doctorsRxCount, changeState }) => {

    const [modalVisiblity, setModalVisiblity] = useState(false);
    const [isDocModal, setIsDocModal] = useState(true);
    let history = useHistory();

    const toggleModal = () => {
        setModalVisiblity(!modalVisiblity)
    }

    const modalValHandle = (val) => {
        setIsDocModal(val);
        toggleModal();
    }

    const openInNewTab= (id) => {
        window.open(process.env.REACT_APP_BASE_API + 'api/rx/' + id, '_blank').focus();
    }
    
    
    const menuAndShowRxHandle = ( id ) => {
        history.push('/prescriptions/did/'+id);
    }
    
    

    return (
        <div className='docCard' style={{cursor: 'pointer'}} onClick={()=> menuAndShowRxHandle(''+doc.user)} >
            <div className='frstSec'>
                <div className="imgWrapper" >
                    { doc.profile_pic ? <img src={process.env.REACT_APP_BASE_API+doc.profile_pic} alt="" /> : <FaUserMd size={50} />} 
                </div>
                <div className='details'>
                    
                    <div  className='items' style={{ cursor: 'pointer', fontSize: 16, minWidth: '100%',}} >
                        <span style={{color: '#0a609e', maxWidth: '190px', maxHeight: '25px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
                            {doc.bmdc_number? doc.title: ''} {doc.full_name}
                        </span> 
                        <div style={{marginLeft: 10, color: doc.bmdc_number? 'green': '#8e0101', padding: '0 5px'}}>
                            {doc.bmdc_number ? <BiMessageSquareCheck/> : <BiMessageSquareX/>} 
                        </div>
                    </div>
                    
                    <div className='items' style={{color: '#000'}}>
                        {doc.bio}
                    </div>
                    <div className='items' style={{fontWeight: 'bold'}} >
                        Speciality: <div>{doc.speaciality}</div>
                    </div>
                    
                </div>
            </div>
            <div className='scndSec'>
                    <div className='items'>
                        BMDC: <div>{doc.bmdc_number? doc.bmdc_number: 'Not Found'}</div>
                    </div>
                    <div className='items'>
                        Email: <div>{doc.email? doc.email: 'Not Found'}</div>
                    </div>
                    <div className='items'>
                        Mobile: <div>{doc.mobile_number? doc.mobile_number: 'Not Found'}</div>
                    </div>
                    <div className='items'>
                        RXs: <div>{doctorsRxCount[''+doc.user]? doctorsRxCount[''+doc.user].length+'+': '0+'}</div>
                    </div>
                    
            </div>

            {/* <div className="modal" style={{ visibility: modalVisiblity ? 'visible' : 'hidden' }}>
                {isDocModal ?
                    <DoctorProfile doc={rx.doctor} /> :
                    <DoctorProfile doc={rx.doctor} />
                }
                <div className="closeBtn" onClick={() => toggleModal()}>
                    <FaTimes />
                </div>
            </div> */}
            
        </div>
    )
}

const mapStateToProps = (state) => ({
    doctorsRxCount: state.rxs.doctorsRxCount
})

const mapDispatchToProps = dispatch => ({
    
})

export default connect(mapStateToProps, mapDispatchToProps)(DoctorCard);
